/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import LayoutDocumentation from "../components/LayoutDocumentation"

function TemplateDocumentation({ data }) {
  // console.log("frontmatter?: ", data.file.childMdx.frontmatter)
  return (
    <LayoutDocumentation>
      <MDXRenderer frontmatter={data.file.childMdx.frontmatter || null}>
        {data.file.childMdx.body}
      </MDXRenderer>
    </LayoutDocumentation>
  )
}

export const query = graphql`
  query ($id: String) {
    file(id: { eq: $id }) {
      id
      childMdx {
        body
      }
    }
  }
`

export default TemplateDocumentation
