/** @jsx jsx */
import { jsx } from "theme-ui"
import { LayoutNoSidebar } from "../../../packages/gatsby-theme-theme-ui"
import SidebarDocumentation from "./SidebarDocumentation"
import WrapperWithSidebar from "../../../packages/gatsby-theme-theme-ui/src/components/WrapperWithSidebar"

function LayoutDocumentation(props) {
  const { children, className } = props

  return (
    <LayoutNoSidebar className={className} fluid={false}>
      <WrapperWithSidebar
        sidebar={
          <SidebarDocumentation
            height="md"
            accordionVariant="accent"
            menuVariant="ui"
            // sx={{
            //   button: { bg: "accent" },
            // }}
          />
        }
        width="md"
        gap={["0px", "0px", "sm", "md", "lg"]}
        fluid={false}
        sx={{ aside: { padding: 0 } }}
      >
        {children}
      </WrapperWithSidebar>
    </LayoutNoSidebar>
  )
}

export default LayoutDocumentation
